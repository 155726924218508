import axios from "axios";
import Util from "./util";

const API_MXD = process.env.REACT_APP_DOMAIN;

//微信分享
const GET_WXJSAPISHA1 = API_MXD + '/app/wechat/jsapi_sha1';

//表单
const GET_FORM_APP_BROWSE = API_MXD + '/form/appbrowse';
const GET_FORM_INFO = API_MXD + '/form/getfd';
const SUBMIT_FORM = API_MXD + '/form/submit';

// 获取活动排行榜清单 (人气排行 table)
const GET_TASK_RANK_LIST = API_MXD + '/app/corp/taskactivity/ranklist'
//获取活动排行榜信息 (个人信息 部分)
const GET_TASK_RANK_INFO = API_MXD + '/app/corp/taskactivity/rankdetail'
//获取好友圈列表清单 (好友助力 table)
const GET_TASK_FRIEND_LIST = API_MXD + '/app/corp/taskactivity/friendlist'
//是否订阅公众号
const IS_SUBSCRIBE = API_MXD + '/app/corp/taskactivity/subscribe'
//获取订阅公众号二维码
const GET_TASK_QRCODE = API_MXD + '/app/corp/taskactivity/oaqrcode'
//领取奖品getTaskReceiveprize
const GET_TASK_RECEIVE_PRIZE = API_MXD + '/app/corp/taskactivity/receiveprize'
//奖品详情
const GET_TASK_PRIZE_DETAIL = API_MXD + '/app/corp/taskactivity/prizedetail'

//获取海报详情
const GET_POSTER_PARTICULARS = API_MXD + '/app/corp/taskactivity/promptdetail'
//好友助力验证
const GROUP_FISSION_TASK_ACTIVITY_HELP_VERIFY = API_MXD + '/app/corp/taskactivity/helpverify'
//邀请海报
//const GET_INVITATION_POSTER=API_MXD + '/app/corp/taskactivity/inviteposter'

//群裂变
const GET_GROUP_FISSION_LEAVEWORD = API_MXD + '/app/corp/groupfission/leaveword'//01.1 群裂变信息采集配置信息
const GET_GROUP_FISSION_LEAVEWORD_SUMMIT = API_MXD + '/app/corp/groupfission/leaveword/summit'//01.2 群裂变提交信息采集
const GET_GROUP_RANK_INFO = API_MXD + '/app/corp/groupfission/rankdetail' // 个人信息
const IS_SUBSCRIBE_GROUP = API_MXD + '/app/corp/groupfission/subscribe' // 是否订阅 公众号
const GET_GROUP_TASK_RANK_LIST = API_MXD + '/app/corp/groupfission/ranklist' // 获取活动排行榜清单 (人气排行 table)
const GET_GROUP_TASK_FRIEND_LIST = API_MXD + '/app/corp/groupfission/friendlist' // 获取活动排行榜清单 (好友排行 table)
const GET_GROUP_PRIZE_DETAIL = API_MXD + '/app/corp/groupfission/prizedetail' //奖品详情
const GET_GROUP_POSTER_INFO = API_MXD + '/app/corp/groupfission/inviteposter' //邀请海报
const GET_GROUP_RECEIVE_PRIZE = API_MXD + '/app/corp/groupfission/receiveprize' //领取奖品
const GET_GROUP_TASK_QRCODE = API_MXD + '/app/corp/groupfission/oaqrcode' //公众号二维码
const GET_GROUP_FISSION_MIDPAGE = API_MXD + '/app/corp/groupfission/midpage' //海报中间页
const GET_GROUP_FISSION_INVITEPOSTER = API_MXD + '/app/corp/groupfission/inviteposter' //获取海报数据
const GET_GROUP_FFISSION_PRIZETNFO = API_MXD + '/app/corp/groupfission/prizeInfo'//获取奖品列表信息

//红包裂变
const GET_REDPACKET_POSTER_DETAIL = API_MXD + '/app/redpacket/doInvit' // 海报数据
const GET_REDPACKET_POSTER_DETAIL_CONTACT_QRCODE = API_MXD + '/app/redpacket/contact_qrcode'
const RECEIVE_REDPACKET = API_MXD + '/app/redpacket/receive' // 领取红包
const GET_REDPACKET_DETAIL = API_MXD + '/app/redpacket/detail' // 红包裂变详情
const GET_REDPACKET_SUPPORT_LIST = API_MXD + '/app/redpacket/supportlist' // 助力好友
const GET_REDPACKET_SUBSCRIBE = API_MXD + '/app/redpacket/subscribe' // 是否订阅公众号
const GET_REDPACKET_SUB_QRCODE = API_MXD + '/app/redpacket/sub_qrcode' //  获取公众号的二维码
const GET_REDPACKET_BARRAGE = API_MXD + '/app/redpacket/barrage' //弹幕

//技术支持 个人中心
const GET_PCENTER_INFO = API_MXD + '/app/productmember/selfcenter' //个人中心
const GET_POSTER_URL = API_MXD + '/app/productmember/poster' //海报URL
const USE_VIP_CARD = API_MXD + '/app/productmember/usecard' //使用会员卡
const BIND_PHONE_NUMBER = API_MXD + '/app/productmember/submitAndBind' //绑定手机号
const CHECK_PHONE_NUMBER = API_MXD + '/app/productmember/phonenoCheck' //检查手机号是否绑定过
const CHECK_WECHAT_BIND = API_MXD + '/app/productmember/isbound' //微信是否绑定手机号

//发送验证码
const SEND_CAPTCHA = API_MXD + '/user/captcha'
//客服详情
const URL_CUSTOMER_SERVICE_DETAIL = API_MXD + '/app/conf/customer_service/detail'

//抽奖裂变

const URL_LOTTERY_DETAIL = API_MXD + '/app/corp/lottery/detail' //抽奖详情
const URL_LOTTERY_SUB_QRCODE = API_MXD + '/app/corp/lottery/sub_qrcode' //获取公众号的二维码
const URL_LOTTERY_SUBSCRIBE = API_MXD + '/app/corp/lottery/subscribe' //是否订阅公众号
const URL_LOTTERY_RECEIVE_RECORD = API_MXD + '/app/corp/lottery/receiveRecords' //抽奖记录
const URL_LOTTERY_HELP_LIST = API_MXD + '/app/corp/lottery/supportList' //助力好友列表
const URL_LOTTERY_INVITE = API_MXD + '/app/corp/lottery/doInvit' //邀请好友
const URL_LOTTERY_INVITE_CONTACT_QRCODE = API_MXD + '/app/corp/lottery/contact_qrcode'
const URL_LOTTERY_BARRAGE = API_MXD + '/app/corp/lottery/barrage' //弹幕
const URL_LOTTERY_DO_LOTTERY = API_MXD + '/app/corp/lottery/drawLottery' //点击抽奖
const URL_LOTTERY_GET_PRIZE = API_MXD + '/app/corp/lottery/getPrize' //立即领取
const URL_LOTTERY_GET_POST = API_MXD + '/app/corp/lottery/genPoster' // 获取海报

//社群红包
const URL_GROUP_RED_GROUP_CODE = API_MXD + '/app/corp/group/redpacket/midpage' //  获取中间页二维码
const URL_GROUP_RED_DETAIL = API_MXD + '/app/corp/group/redpacket/detail' //  详情页
const URL_GROUP_RED_SUPPORT_LIST = API_MXD + '/app/corp/group/redpacket/supportlist' // 助力好友
const URL_GROUP_RED_SUB_QRCODE = API_MXD + '/app/corp/group/redpacket/sub_qrcode' //  获取公众号的二维码
const URL_GROUP_RED_RECEIVE = API_MXD + '/app/corp/group/redpacket/receive' //  领取红包
const URL_GROUP_RED_DO_INVITE = API_MXD + '/app/corp/group/redpacket/doInvit' //  邀请（获取二维码或者海报）
const URL_GROUP_RED_SUBSCRIBE = API_MXD + '/app/corp/group/redpacket/subscribe' //  是否订阅公众号
const URL_GROUP_RED_BARRAGE = API_MXD + '/app/corp/group/redpacket/barrage' //  弹幕

//群活码
const URL_LIVE_CODE_GET_PAGE_TYPE = API_MXD + '/app/wxw/roomlivecode/pagetype' //获取页面类型
const URL_LIVE_CODE_GET_GROUP_LIST = API_MXD + '/app/wxw/roomlivecode/groupinfo' //分组页面获取分组列表
const URL_LIVE_CODE_GET_GROUP_SINGLE_INFO = API_MXD + '/app/wxw/roomlivecode/selectgroup' //选择分组
const URL_LIVE_CODE_GET_GROUP_MIDPAGE_INFO = API_MXD + '/app/wxw/roomlivecode/midpageinfo' //获取二维码页面信息
const URL_LIVE_CODE_HOLD_QRCODE = API_MXD + '/app/wxw/roomlivecode/holdqrcode' //长按二维码

class Api {

    static appendToken(url) {
        let token = window.sessionStorage.getItem("token");
        // let token = '3ea9f5dd-8d1e-42d5-b857-8440f4285275';
        if (!Util.isEmpty(token)) {
            if (url.indexOf("?") == -1) {
                url = url + "?token=" + token
            } else {
                url = url + "&token=" + token
            }
        }
        return url;
    }

    static doGet(url, success, error, docatch) {
        axios.get(this.appendToken(url))
            .then((msg) => {
                let data = msg.data;
                if (data.respCode == 0) {
                    if (success) {
                        success(data.respMsg)
                    }
                } else {
                    if (error) {
                        error(data)
                    }
                }
            })
            .catch((msg) => {
                if (docatch) {
                    docatch(msg)
                } else if (error) {
                    error(msg)
                }
            })
    }

    static doPost(url, bodyData, success, error, docatch) {
        axios.post(this.appendToken(url), bodyData)
            .then((msg) => {
                let data = msg.data;
                if (data.respCode == 0) {
                    if (success) {
                        success(data.respMsg)
                    }
                } else {
                    if (error) {
                        error(data)
                    }
                }
            })
            .catch((msg) => {
                if (docatch) {
                    docatch(msg)
                } else if (error) {
                    error(msg)
                }
            })
    }

    /**
     * 长按二维码
     * @param queryParam
     * @param success
     * @param error
     */
    static holdLivecodeQrCode(queryParam, success, error) {
        this.doPost(URL_LIVE_CODE_HOLD_QRCODE + `?data=${queryParam}`, {}, success, error)
    }

    /**
     * 获取二维码页面信息
     * @param queryParam
     * @param success
     * @param error
     */
    static getRliveCodeMidPageInfo(queryParam, success, error) {
        this.doGet(URL_LIVE_CODE_GET_GROUP_MIDPAGE_INFO + `?data=${queryParam}`, success, error)
    }

    /**
     * 选择分组
     * @param queryParam
     * @param success
     * @param error
     */
    static getRliveCodeSingleInfo(queryParam, success, error) {
        let query = `?groupId=${queryParam.groupId}&data=${queryParam.data}`
        this.doPost(URL_LIVE_CODE_GET_GROUP_SINGLE_INFO + query, {}, success, error)
    }

    /**
     * 分组页面获取分组列表
     * @param query
     * @param success
     * @param error
     */
    static getRliveCodeGroupList(queryParam, success, error) {
        this.doGet(URL_LIVE_CODE_GET_GROUP_LIST + `?data=${queryParam}`, success, error)
    }

    /**
     * 群活码获取页面类型
     * @param query
     * @param success
     * @param error
     */
    static getRliveCodePageType(queryParam, success, error) {
        this.doGet(URL_LIVE_CODE_GET_PAGE_TYPE + `?data=${queryParam}`, success, error)
    }

    /**
     * 红包裂变 弹幕
     * @param queryParam
     * @param success
     * @param error
     */
    static getRedpacketBarrage(queryParam, success, error) {
        let query = `?redPacketId=${queryParam.redPacketId}&pageNum=${queryParam.pageNum}&pageSize=${queryParam.pageSize}`
        this.doGet(GET_REDPACKET_BARRAGE + query, success, error)
    }

    /**
     * 社群红包 弹幕
     * @param queryParam
     * @param success
     * @param error
     */
    static getGroupRedBarrage(queryParam, success, error) {
        let query = `?groupRedPacketId=${queryParam.groupRedPacketId}&pageSize=${queryParam.pageSize}&pageNum=${queryParam.pageNum}`
        this.doGet(URL_GROUP_RED_BARRAGE + query, success, error)
    }

    /**
     * 社群红包 是否订阅公众号
     * @param id
     * @param success
     * @param error
     */
    static getGroupRedSubscribe(id, success, error) {
        this.doGet(URL_GROUP_RED_SUBSCRIBE + `?groupRedPacketId=${id}`, success, error)
    }

    /**
     * 社群红包 邀请（获取二维码或者海报）
     * @param bodyData
     * @param success
     * @param error
     */
    static groupRedDoInvite(bodyData, success, error) {
        this.doPost(URL_GROUP_RED_DO_INVITE, bodyData, success, error)
    }

    /**
     * 社群红包 领取红包
     * @param bodyData
     * @param success
     * @param error
     */
    static receiveGroupRed(bodyData, success, error) {
        this.doPost(URL_GROUP_RED_RECEIVE, bodyData, success, error)
    }

    /**
     * 社群红包 获取公众号的二维码
     * @param id
     * @param success
     * @param error
     */
    static getGroupRedSub_QrCode(id, success, error) {
        this.doGet(URL_GROUP_RED_SUB_QRCODE + `?groupRedPacketId=${id}`, success, error)
    }

    /**
     * 社群红包 助力好友
     * @param queryParam
     * @param success
     * @param error
     */
    static getGroupRedHelpList(queryParam, success, error) {
        let query = `?pageSize=${queryParam.pageSize}&pageNum=${queryParam.pageNum}&grfId=${queryParam.grfId}`
        this.doGet(URL_GROUP_RED_SUPPORT_LIST + query, success, error)
    }

    /**
     * 社群红包 详情页
     * @param id
     * @param success
     * @param error
     */
    static getGroupRedDetail(bodyData, success, error) {
        this.doPost(URL_GROUP_RED_DETAIL, bodyData, success, error)
    }

    /**
     * 社群红包 获取中间页二维码
     * @param id
     * @param success
     * @param error
     */
    static getGroupRedCode(id, success, error) {
        this.doGet(URL_GROUP_RED_GROUP_CODE + `?groupRedPacketId=${id}`, success, error)
    }

    /**
     *  获取海报
     * @param bodyData
     * @param success
     * @param error
     */
    static getLotteryPoster(bodyData, success, error) {
        this.doPost(URL_LOTTERY_GET_POST, bodyData, success, error)
    }

    /**
     *  获取海报
     * @param bodyData
     * @param success
     * @param error
     */
    static getLotteryContactQrcode(bodyData, success, error) {
        this.doPost(URL_LOTTERY_INVITE_CONTACT_QRCODE, bodyData, success, error)
    }


    /**
     * 客服详情
     * @param csId
     * @param success
     * @param error
     */
    static getCustomerDetail(csId, success, error) {
        this.doGet(URL_CUSTOMER_SERVICE_DETAIL + `?csId=${csId}`, success, error)
    }


    /**
     * 抽奖裂变 立即领取
     * @param bodyData
     * @param success
     * @param error
     */
    static getLotteryPrize(bodyData, success, error) {
        this.doPost(URL_LOTTERY_GET_PRIZE, bodyData, success, error)

    }

    /**
     * 抽奖裂变 点击抽奖
     * @param bodyData
     * @param success
     * @param error
     */
    static doLottery(bodyData, success, error) {
        this.doPost(URL_LOTTERY_DO_LOTTERY, bodyData, success, error)
    }


    /**
     * 抽奖裂变 弹幕
     * @param queryParam
     * @param success
     * @param error
     */
    static getLotteryBarrage(queryParam, success, error) {
        let query = `?lotteryId=${queryParam.lotteryId}&pageNum=${queryParam.pageNum}&pageSize=${queryParam.pageSize}`
        this.doGet(URL_LOTTERY_BARRAGE + query, success, error)
    }


    /**
     * 抽奖裂变 助力好友列表
     * @param bodyData
     * @param success
     * @param error
     */
    static getLotteryHelpList(bodyData, success, error) {
        this.doPost(URL_LOTTERY_HELP_LIST, bodyData, success, error)
    }

    /**
     * 抽奖裂变 邀请好友
     * @param bodyData
     * @param success
     * @param error
     */
    static doLotteryInvite(bodyData, success, error) {
        this.doPost(URL_LOTTERY_INVITE, bodyData, success, error)
    }

    /**
     * 抽奖裂变 抽奖记录
     * @param bodyData
     * @param success
     * @param error
     */
    static getLotteryRecord(bodyData, success, error) {
        this.doPost(URL_LOTTERY_RECEIVE_RECORD, bodyData, success, error)
    }

    /**
     * 抽奖裂变 是否订阅公众号
     * @param lotteryId
     * @param success
     * @param error
     */
    static getLotterySubscribe(lotteryId, success, error) {
        this.doGet(URL_LOTTERY_SUBSCRIBE + `?lotteryId=${lotteryId}`, success, error)
    }

    /**
     * 抽奖裂变 获取公众号的二维码
     * @param lotteryId
     * @param success
     * @param error
     */
    static getLotterySubQrcode(lotteryId, success, error) {
        this.doGet(URL_LOTTERY_SUB_QRCODE + `?lotteryId=${lotteryId}`, success, error)
    }

    /**
     * 抽奖裂变 抽奖详情
     * @param bodyData
     * @param success
     * @param error
     */
    static getLotteryDetail(bodyData, success, error) {
        this.doPost(URL_LOTTERY_DETAIL, bodyData, success, error)
    }

    /**
     * 发送验证码
     * @param bodyData
     * @param success
     * @param error
     */
    static sendCaptcha(bodyData, success, error) {
        this.doPost(SEND_CAPTCHA, bodyData, success, error)
    }


    /**
     * 领取会员 微信是否绑定手机号
     * @param success
     * @param error
     */
    static checkWeChatBind(success, error) {
        this.doGet(CHECK_WECHAT_BIND, success, error)
    }

    /**
     * 领取会员 检查手机号是否绑定
     * @param bodyData
     * @param success
     * @param error
     */
    static checkPhoneNumber(bodyData, success, error) {
        this.doPost(CHECK_PHONE_NUMBER, bodyData, success, error)
    }

    /**
     * 领取会员 绑定手机号
     * @param bodyData
     * @param success
     * @param error
     */
    static bindPhoneNumber(bodyData, success, error) {
        this.doPost(BIND_PHONE_NUMBER, bodyData, success, error)
    }

    /**
     * 个人中心 使用会员卡
     * @param bodyData: {pType: pType}
     * @param success
     * @param error
     */
    static useVipCard(bodyData, success, error) {
        this.doPost(USE_VIP_CARD, bodyData, success, error)
    }

    /**
     * 个人中心 海报url
     * @param success
     * @param error
     */
    static getPosterUrl(success, error) {
        this.doGet(GET_POSTER_URL, success, error)
    }

    /**
     * 个人中心
     * @param success
     * @param error
     */
    static getPcenterInfo(success, error) {
        this.doGet(GET_PCENTER_INFO, success, error)
    }


    /**
     * 获取公众号的二维码
     * @param redPacketId
     * @param success
     * @param error
     */
    static getRedSubqr_Code(redPacketId, success, error) {
        this.doGet(GET_REDPACKET_SUB_QRCODE + `?redPacketId=${redPacketId}`, success, error)
    }

    /**
     * 红包 是否订阅公众号
     * @param redpacketId
     * @param success
     * @param error
     */
    static getRedSubscribe(redpacketId, success, error) {
        this.doGet(GET_REDPACKET_SUBSCRIBE + `?redpacketId=${redpacketId}`, success, error)
    }

    /**
     * 红包 助力好友
     * @param queryParam
     * @param success
     * @param error
     */
    static getRedHelpList(queryParam, success, error) {
        let query = `?pageSize=${queryParam.pageSize}&pageNum=${queryParam.pageNum}&rfId=${queryParam.rfId}`
        this.doGet(GET_REDPACKET_SUPPORT_LIST + query, success, error)
    }

    /**
     * 红包裂变详情
     * @param queryParam
     * @param success
     * @param error
     */
    static getRedpacketInfo(queryParam, success, error) {
        let query = `?rfId=${queryParam.rfId}&redPacketId=${queryParam.redPacketId}`
        this.doGet(GET_REDPACKET_DETAIL + query, success, error)
    }

    /**
     * 领取红包
     * @param bodyData
     * @param success
     * @param error
     */
    static receiveRedpacket(bodyData, success, error) {
        this.doPost(RECEIVE_REDPACKET, bodyData, success, error)
    }

    /**
     * 红包 海报数据
     * @param bodyData
     * @param success
     * @param error
     */
    static getRedpacketPoster(bodyData, success, error) {
        this.doPost(GET_REDPACKET_POSTER_DETAIL, bodyData, success, error)
    }

    /**
     * 红包 海报数据
     * @param bodyData
     * @param success
     * @param error
     */
    static getRedpacketContactQrcode(bodyData, success, error) {
        this.doPost(GET_REDPACKET_POSTER_DETAIL_CONTACT_QRCODE, bodyData, success, error)
    }

    /**
     * 获取海报详情
     * @param taskId
     * @param token
     * @param success
     * @param error
     */
    static getPosterParticulars(taskId, success, error) {
        this.doGet(GET_POSTER_PARTICULARS + '?taskId=' + taskId, success, error)
    }
    /**
     * 10 好友助力验证
     * @param verifykey
     * @param token
     * @param success
     * @param error
     */
    static GroupFissionTaskActivityHelpVerify(verifykey,success,error){
        this.doGet(GROUP_FISSION_TASK_ACTIVITY_HELP_VERIFY + '?verifykey=' + verifykey ,success,error)
    }


    /**
     * 获取活动排行榜清单 (人气排行 table)
     * @param queryParam
     * @param taskId
     * @param success
     * @param error
     */
    static getTaskRankList(queryParam, bodyData, success, error) {
        let query = `?pageNum=${queryParam.pageNum}&pageSize=${queryParam.pageSize}`
        this.doPost(GET_TASK_RANK_LIST + query, bodyData, success, error)
    }

    /**
     * 获取活动排行榜信息 (个人信息 部分)
     * @param taskId
     * @param success
     * @param error
     */
    static getTaskRankInfo(taskId, success, error) {
        this.doGet(GET_TASK_RANK_INFO + `?taskId=${taskId}`, success, error)
    }

    /**
     *  获取好友圈列表清单 (好友助力 table)
     * @param queryParam
     * @param success
     * @param error
     */
    static getTaskFriendList(queryParam, success, error) {
        let query = `?pageNum=${queryParam.pageNum}&pageSize=${queryParam.pageSize}`
        this.doGet(GET_TASK_FRIEND_LIST + query, success, error)
    }

    /**
     * 是否订阅公众号 0未订阅 1已经订阅
     * @param taskId
     * @param success
     * @param error
     */
    static isSubscribe(taskId, success, error) {
        this.doGet(IS_SUBSCRIBE + `?taskId=${taskId}`, success, error)
    }

    /**
     * 获取订阅公众号二维码
     * @param taskId
     * @param success
     * @param error
     */
    static getTaskQrcode(taskId, success, error) {
        this.doGet(GET_TASK_QRCODE + `?taskId=${taskId}`, success, error)
    }

    /**
     * 领取奖品
     * @param bodyData prizeLev
     * @param success
     * @param error
     */
    static getTaskPrize(bodyData, success, error) {
        this.doPost(GET_TASK_RECEIVE_PRIZE, bodyData, success, error)
    }

    /**
     * 奖品详情
     * @param prizeLev
     * @param success
     * @param error
     */
    static getTaskPrizeDetail(prizeLev, taskId, success, error) {
        this.doGet(GET_TASK_PRIZE_DETAIL + `?prizeLev=${prizeLev}&taskId=${taskId}`, success, error)
    }

    /**
     * 获取微信js-sdk签名
     * @param url
     * @param success
     * @param error
     * @constructor
     */
    static GetWXJsApiSha1(url, success, error) {
        this.doGet(GET_WXJSAPISHA1 + "?url=" + url, success, error)
    }

    /**
     * 表单预览
     * @param fd
     * @param success
     * @param error
     */
    static formAppbrowse(fd, success, error) {
        this.doPost(GET_FORM_APP_BROWSE + "?key=" + fd, {}, success, error)
    }

    /**
     * 获取表单数据
     * @param id
     * @param success
     * @param error
     */
    static getFormInfo(id, success, error) {
        this.doGet(GET_FORM_INFO + '?fd=' + id, success, error)
    }

    /**
     * 提交表单
     * @param param
     * @param success
     * @param error
     */
    static submitForm(param, success, error) {
        this.doPost(SUBMIT_FORM, param, success, error)
    }

    /**
     * 01.1 群裂变信息采集配置信息
     * @param fissionId
     * @param success
     * @param error
     */
    static getGroupFissionLeaveWord(fissionId, success, error) {
        this.doGet(GET_GROUP_FISSION_LEAVEWORD + `?fissionId=${fissionId}`, success, error)
    }

    /**
     * 01.2 群裂变提交信息采集
     * @param fissionId
     * @param success
     * @param error
     */
    static getGroupFissionLeavewordSummit(fissionId, bodyData, success, error) {
        this.doPost(GET_GROUP_FISSION_LEAVEWORD_SUMMIT + `?fissionId=${fissionId}`, bodyData, success, error)
    }

    /**
     * 群裂变 活动排行榜信息 (个人信息)
     * @param fissionId
     * @param success
     * @param error
     */
    static getGroupRankInfo(fissionId, success, error) {
        this.doGet(GET_GROUP_RANK_INFO + `?fissionId=${fissionId}`, success, error)
    }

    /**
     * 群裂变 是否订阅公众号 0未订阅 1已经订阅
     * @param taskId
     * @param success
     * @param error
     */
    static isSubscribeGroup(fissionId, success, error) {
        this.doGet(IS_SUBSCRIBE_GROUP + `?fissionId=${fissionId}`, success, error)
    }

    /**
     * 群裂变 获取活动排行榜清单 (人气排行 table)
     * @param queryParam
     * @param fissionId
     * @param success
     * @param error
     */
    static getGroupTaskRankList(queryParam, bodyData, success, error) {
        let query = `?pageNum=${queryParam.pageNum}&pageSize=${queryParam.pageSize}`
        this.doPost(GET_GROUP_TASK_RANK_LIST + query, bodyData, success, error)
    }

    /**
     *  群裂变 获取好友圈列表清单 (好友助力 table)
     * @param queryParam
     * @param success
     * @param error
     */
    static getGroupTaskFriendList(queryParam, success, error) {
        let query = `?pageNum=${queryParam.pageNum}&pageSize=${queryParam.pageSize}&fissionId=${queryParam.fissionId}`
        this.doGet(GET_GROUP_TASK_FRIEND_LIST + query, success, error)
    }

    /**
     * 群裂变 奖品详情
     * @param prizeLev,fissionId
     * @param success
     * @param error
     */
    static getGroupPrizeDetail(prizeLev, fissionId, success, error) {
        this.doGet(GET_GROUP_PRIZE_DETAIL + `?prizeLev=${prizeLev}&fissionId=${fissionId}`, success, error)
    }

    /**
     * 群裂变 邀请海报详情
     * @param fissionId
     * @param token
     * @param success
     * @param error
     */
    static getGroupPosterInfo(fissionId, success, error) {
        this.doGet(GET_GROUP_POSTER_INFO + '?fissionId=' + fissionId, success, error)
    }

    /**
     * 群裂变 领取奖品
     * @param bodyData prizeLev
     * @param success
     * @param error
     */
    static getGroupPrize(bodyData, success, error) {
        this.doPost(GET_GROUP_RECEIVE_PRIZE, bodyData, success, error)
    }

    /**
     * 群裂变海报中间页
     * @param fissionId
     * @param token
     * @param success
     * @param error
     */
    static getGroupFissionMidpage(fissionId, success, error) {
        this.doGet(GET_GROUP_FISSION_MIDPAGE + '?fissionId=' + fissionId, success, error)
    }

    /**
     * 群裂变 获取订阅公众号二维码
     * @param fissionId
     * @param success
     * @param error
     */
    static getGroupTaskQrcode(fissionId, success, error) {
        this.doGet(GET_GROUP_TASK_QRCODE + `?fissionId=${fissionId}`, success, error)
    }


    /**
     * 群裂变获取海报
     * @param fissionId
     * @param token
     * @param success
     * @param error
     */
    static getGroupFissionInviteposter(fissionId, success, error) {
        this.doGet(GET_GROUP_FISSION_INVITEPOSTER + '?fissionId=' + fissionId, success, error)
    }

    /**
     * 群裂变获取奖品列表
     * @param fissionId
     * @param token
     * @param success
     * @param error
     */
    static getGroupFissionPrizeInfo(fissionId, success, error) {
        this.doGet(GET_GROUP_FFISSION_PRIZETNFO + '?fissionId=' + fissionId, success, error)
    }
}

export default Api

