/**
 * @Description: 抽奖裂变
 * @author Shi_qshan
 * @date 2021/11/1
*/
import React, {useEffect, useState} from 'react'
import {Route} from "react-router-dom";
import loadable from "../../loadable";
import Util from "../../../util/util";

const Lottery = loadable(() => import('./prize'));
const Poster = loadable(() => import('./poster'));
// const Demo = loadable(() => import('./turn'));
const FissionLotteryRouter = (props) => {

    const [init, setInit] = useState(0)
    useEffect(() => {
        let token = Util.getUrlParam('token')
        // let token = '406cf016-64b2-4b0a-8c54-75690be23cac'
        if (token) {
            sessionStorage.setItem('token', token)
        }
        setInit(1)
    });
    return (
        <>
            {
                init == 1 ?
                    <>
                        <Route path={props.match.url + "/poster"} component={Poster}/>
                        <Route path={props.match.url + "/prize"} component={Lottery}/>
                    </>
                    : ''
            }

        </>
    )
};

export default FissionLotteryRouter