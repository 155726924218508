import React, {useEffect, useState} from 'react';
import IconZkLogo from "../iconfont/logo/zkLogo";
import {Modal, Toast} from "antd-mobile";
import Api from "../../util/Api";
import Util from "../../util/util";
import defaultAvatar from '../../images/rlivecode/default-avatar.png'

let timeOutEvent = 0;//长按定时器
const QrCodeMode = (props) => {
    const {queryData} = props;
    const [visible, setVisible] = useState(false)
    const [data, setData] = useState({
        middlePageType: '',
        middlePageTitle: '',//中间页页面标题
        roomQRCodeUrl: '', //群二维码url
        middlePageConf: {
            showRoomInfo: '',
            roomInfo: {
                name: '', //群名称
                avatorUrl: '' //群头像
            },
            roomLogoUrl: '', //群的企业logo
            footerDesc: '', //底部文案
            contactCodeUrl: '' //活动方图片url
        },
    })

    useEffect(() => {
        let linkData = '';
        if (props.location && props.location.state) {
            linkData = props.location.state
        }
        Api.getRliveCodeMidPageInfo(queryData || linkData, data => {
            Util.changeHtmlTitle(data.middlePageTitle)
            setData(data)
        }, error => {
            Toast.fail('中间页信息请求失败')
        })
    }, [])

    const closeModal = () => {
        setVisible(false)
    }

    const touchStart = (e) => {
        e.preventDefault();
        e.stopPropagation();
        timeOutEvent = setTimeout(() => {
            //此处调用长按时的接口
            Api.holdLivecodeQrCode(queryData, () => {
            }, () => {
            })
        }, 500)
        return false;
    }

    const touchEnd = (e) => {
        e.preventDefault();
        e.stopPropagation();
        clearTimeout(timeOutEvent)
        return false
    }

    return (
        <div className={'grouping-max'}>
            <div className={'live-code-top'}>
                <div className={'live-avatar-and-name'}>
                    <img src={data.middlePageConf.roomInfo.avatorUrl || defaultAvatar}/>
                    <span>{data.middlePageConf.roomInfo.name}</span>
                </div>
                <div className={'live-qr-coder-max'}>
                    <div className={'live-qr-coder'}>
                        <img src={data.roomQRCodeUrl} style={{width: '100%', height: '100%'}} onTouchStart={touchStart}
                             onTouchEnd={touchEnd}/>
                        {
                            data.middlePageConf.roomLogoUrl ?
                                <img src={data.middlePageConf.roomLogoUrl} className={'live-qecode-roomLogoImg'}/> : ''
                        }
                    </div>
                    <p>{data.middlePageConf.footerDesc}</p>
                </div>
            </div>

            {
                data.middlePageConf.contactCodeUrl ?
                    <p className={'call-us'} onClick={() => setVisible(true)}>{'联系活动方'}</p> : ''
            }
            <div className='live-code-support-wraper'>
                <a href={"https://www.zikunscrm.com"} className={'live-code-support'}>
                    <span><IconZkLogo/></span>
                    <span>{'紫鲲提供技术支持'}</span>
                </a>
            </div>
            {/*{铺满全屏的二维码 img}*/}
            {/*<img src={data.roomQRCodeUrl} className={'fill-screen-qrcode'}*/}
            {/*     onTouchStart={touchStart}*/}
            {/*     onTouchEnd={touchEnd}*/}
            {/*/>*/}

            {/*{二维码 modal}*/}
            <Modal
                visible={visible}
                maskClosable={true} //点击蒙层是否允许关闭
                onClose={closeModal} //点击 x 或 mask 回调
                transparent={true} //是否背景透明
                //closable={true} //是否显示关闭按钮
                afterClose={() => {
                }} //Modal 完全关闭后的回调
                // className={'recive_modal'}
            >
                <div className={'live-qrcode'}>
                    <img src={data.middlePageConf.contactCodeUrl} className={'live-qrimg'}/>
                </div>
                <p style={{marginBottom: '0px'}}>长按识别二维码联系活动方</p>
            </Modal>
        </div>
    );
}

export default QrCodeMode;