/**
 * @author Yaochao
 * @date 2021/8/6 16:28
 * @description 裂变获客-企微任务宝
 */
import React, {useEffect, useState} from 'react'
import {Route} from "react-router-dom";
import loadable from "../../loadable";
import Util from "../../../util/util";

const Poster = loadable(() => import('./poster'));
const Rank = loadable(() => import('./rank'));
const Verification = loadable(()=>import('./verification'))
const FissionCustomerRouter = (props) => {

    const [init,setInit] = useState(0)
    useEffect(()=>{
        let token = Util.getUrlParam('token')
        // let token = '8214a0ad-e231-46a8-9064-8467fb8aa716'
        if (token) {
            sessionStorage.setItem('token', token)
        }
        setInit(1)
    })
    return (
        <>
            {
                init == 1 ?
                    <>
                        <Route path={props.match.url + "/poster"} component={Poster}/>
                        <Route path={props.match.url + "/rank"} component={Rank}/>
                        <Route path={props.match.url + "/verification"} component={Verification}/>
                    </>
                    : ''
            }

        </>
    )
};

export default FissionCustomerRouter