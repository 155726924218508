import React,{useEffect} from 'react'
import OAuthImg from '../images/oauth_guide.png'
import Util from "../util/util";

export default function OAuthGuide() {

    useEffect(()=>{
        Util.changeHtmlTitle("微信授权引导")
    })

    return (
        <div>
            <img className='oauth_guide_img' src={OAuthImg}/>
            <style jsx>{`
              .oauth_guide_img {
                width: 100%;
                position: fixed;
                bottom: 0;
              }
            `}</style>
        </div>
    )
}