import React from 'react';
import end from '../../images/rlivecode/end.jpg'
import IconZkLogo from "../iconfont/logo/zkLogo";
const ActivityEnd = (props) => {
    return (
        <div className={'active-end-max'}>
            <img src={end} style={{height: '1.7rem'}}/>
            <p className={'active-end-text'}>活动已结束</p>
            <div className='live-code-support-wraper'>
                <a href={"https://www.zikunscrm.com"} className={'live-code-support'}>
                    <span><IconZkLogo/></span>
                    <span>{'紫鲲提供技术支持'}</span>
                </a>
            </div>
        </div>
    )
}

export default ActivityEnd;