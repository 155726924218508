import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import FissionCustomerRouter from "./pages/fission/customer";
import FissionChatRouter from "./pages/fission/chat";
import FissionRedpacketRouter from "./pages/fission/redpacket";
import FissionLotteryRouter from "./pages/fission/lottery";
import InviterRouter from "./pages/inviter";
import FormRouter from "./pages/form/form";
import FissionGroupRedRouter from "./pages/fission/groupred";
import RliveCode from "./pages/rlivecode/index";
import OAuthGuide from "./pages/oauth_guide";

class App extends Component {

    componentDidMount() {
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path={"/fission/customer"} component={FissionCustomerRouter}/>
                    <Route path={"/fission/chat"} component={FissionChatRouter}/>
                    <Route path={"/fission/redpacket"} component={FissionRedpacketRouter}/>
                    <Route path={"/fr"} component={FissionRedpacketRouter}/>
                    <Route path={"/fission/lottery"} component={FissionLotteryRouter}/>
                    <Route path={"/fission/groupred"} component={FissionGroupRedRouter}/>
                    <Route path={"/inviter"} component={InviterRouter}/>
                    <Route path={"/form"} component={FormRouter}/>
                    <Route path={"/livecode/index/:data"} component={RliveCode}/>
                    <Route path={"/oauth_guide"} component={OAuthGuide}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
