/**
 * @author Yaochao
 * @date 2021/9/29 14:05
 * @description 邀请好友注册，领取VIP时长
 */
import React, {useEffect, useState} from "react";
import Util from "../../util/util";
import {Route} from "react-router-dom";
import loadable from "../loadable";

const ReceiveVip = loadable(() => import('./receive'));
const PersonalCenter = loadable(() => import('./pcenter'));

const InviterRouter = (props) => {

    const [init, setInit] = useState(0)
    useEffect(() => {
        let token
        //url带token 用url的token
        if (Util.parseParmStr(window.location.href)){
            token = Util.getUrlParam('token')
        }else {
            // url没有token ， 用之前页面存的token
            token = window.sessionStorage.getItem('token')
        }
        //let token = Util.getUrlParam('token') || window.sessionStorage.getItem('token')
        if (token) {
            sessionStorage.setItem('token', token)
        }
        setInit(1)
    });
    return (
        <>
            {
                init == 1 ?
                    <>
                        <Route path={props.match.url + "/receive"} component={ReceiveVip}/>
                        <Route path={props.match.url + "/pcenter"} component={PersonalCenter}/>
                    </>
                    : ''
            }

        </>
    )

}

export default InviterRouter