class Path {

    //跳转排行榜
    static getSkipRankingList() {
        return '/fission/customer/rank';
    }

    static getFormSuccess() {
        return '/form/success';
    }

    static getFormDetail() {
        return '/form/detail';
    }
    //跳转群联排行榜
    static getGroupRankingList(){
        return '/fission/chat/rank'
    }

    //跳转 个人中心
    static getPcenter() {
        return '/inviter/pcenter'
    }

    //跳转 领30会员
    static getReceiveVip() {
        return '/inviter/receive'
    }
}

export {Path}













