import React, {useEffect, useState} from 'react';
import {Toast} from "antd-mobile";
import Api from "../../util/Api";

let timeOutEvent = 0;//长按定时器
const PosterMode = (props) => {
    const {queryData} = props;
    const [data, setData] = useState({
        middlePageType: '',
        middlePageTitle: '',//中间页页面标题
        roomQRCodeUrl: '', //群二维码url
        middlePageConf: {
            showRoomInfo: '',
            roomInfo: {
                name: '', //群名称
                avatorUrl: '' //群头像
            },
            roomLogoUrl: '', //群的企业logo
            footerDesc: '', //底部文案
            contactCodeUrl: '', //活动方图片url
            posterData: {
                posterPic: '',
                qrcode: {
                    height: 99,
                    left: 0,
                    top: 0,
                    width: 99,
                }
            }
        },
    })
    useEffect(() => {
        let linkData = '';
        if (props.location && props.location.state) {
            linkData = props.location.state
        }
        Api.getRliveCodeMidPageInfo(queryData || linkData, data => {
            //计算二维码的相对位置
            const {qrcode, width, height} = data.middlePageConf.posterData
            let rate = document.body.clientWidth / width
            console.log('9898rate', rate)
            qrcode.left = Math.round(qrcode.left * rate)
            qrcode.top = Math.round(qrcode.top * rate)
            qrcode.height = Math.round(qrcode.height * rate)
            qrcode.width = Math.round(qrcode.width * rate)
            data.middlePageConf.posterData.height = Math.round(height * rate)
            setData(data)

        }, error => {
            Toast.fail('中间页信息请求失败')
        })
    }, [])

    const touchStart = (e) => {
        e.preventDefault();
        e.stopPropagation();
        timeOutEvent = setTimeout(() => {
            //此处调用长按时的接口
            Api.holdLivecodeQrCode(queryData, () => {
            }, () => {
            })
        }, 500)
    }

    const touchEnd = (e) => {
        e.preventDefault();
        e.stopPropagation();
        clearTimeout(timeOutEvent)
        return false
    }

    return (
        <div className={'poster-max'}>
            {/*{*/}
            {/*    data.roomQRCodeUrl ? <img src={data.roomQRCodeUrl} className={'fill-screen-qrcode'}*/}
            {/*                              onTouchStart={touchStart}*/}
            {/*                              onTouchEnd={touchEnd}*/}
            {/*    /> : ''*/}
            {/*}*/}
            {
                data.middlePageConf.posterData.posterPic ? <img src={data.middlePageConf.posterData.posterPic}
                                                                style={{width: '100%', display: "block"}}/> : ''
            }
            {
                data.roomQRCodeUrl ? <img src={data.roomQRCodeUrl} className={'poster-qrcode'}
                                          onTouchStart={touchStart}
                                          onTouchEnd={touchEnd}
                                          style={{
                                              left: data.middlePageConf.posterData.qrcode.left,
                                              top: data.middlePageConf.posterData.qrcode.top,
                                              height: data.middlePageConf.posterData.qrcode.height,
                                              width: data.middlePageConf.posterData.qrcode.width,
                                          }}
                /> : ''
            }
        </div>
    );
}

export default PosterMode;