import React, {useEffect, useState} from 'react';
import {Button, Toast} from "antd-mobile";
import IconZkLogo from "../iconfont/logo/zkLogo";
import Api from "../../util/Api";
import {withRouter} from "react-router-dom";
import Util from "../../util/util";

const SelectGroup = (props) => {

    const {queryData, setType} = props;
    const [data, setData] = useState({
        title: '',
        desc: '',
        groupList: [],
    })

    useEffect(() => {
        Api.getRliveCodeGroupList(queryData, data => {
            Util.changeHtmlTitle(data.title)
            setData(data)
        }, error => {
            Toast.fail('分组列表请求失败')
        })
    }, [])

    const getInfo = groupId => {
        let queryParam = {
            data: queryData,
            groupId: groupId,
        }
        Api.getRliveCodeSingleInfo(queryParam, data => {
            // 2：二维码标准模式，3：二维码海报模式
            setType(data.type);
        }, error => {
            Toast.info('分组信息请求失败')
        })
    }

    return (
        <div className={'select-group-max'}>
            <div className={'blue-title-box'}>
                <span>{data.desc}</span>
            </div>
            <div className={'select-group-box'}>
                <div className={'select-group-top'}>
                    <div className={'select-group-btn'}>请选择</div>
                </div>
                <div className={'group-content'}>
                    {
                        data.groupList.map((item, index) => {
                            return (
                                <Button size='large' className={'livecode-earch-group'} key={item.groupId}
                                        onClick={() => getInfo(item.groupId)}>
                                    {item.groupName}
                                </Button>
                            )
                        })
                    }
                </div>
            </div>
            <div style={{height: '0.4rem'}}></div>
            <a href={"https://www.zikunscrm.com"} className={'live-code-support'}>
                <span><IconZkLogo/></span>
                <span>{'紫鲲提供技术支持'}</span>
            </a>
            <div style={{height: '0.95rem'}}></div>
        </div>
    )
}

export default withRouter(SelectGroup);