/**
 * @author Yaochao
 * @date 2021/7/23 9:47
 * @description
 */
import React from 'react';
import Loadable from 'react-loadable';
import Loading from "./common/loading";

export default (loader) => {
    return Loadable({
        loader,
        loading() {
            return <Loading/>
        },
    });
}