/**
 * @author Yaochao
 * @date 2021/8/28 17:29
 * @description 社群裂变
 */
import React, {useEffect, useState} from 'react'
import {Route} from "react-router-dom";
import loadable from "../../loadable";
import Util from "../../../util/util";

const Rank = loadable(() => import('./rank'));
const newRank = loadable(() => import('./newRank'));
const Poster =loadable(()=>import('./poster'));
const ScanCodeGroup =loadable(()=>import('./scancodegroup'));
const FissionCustomerRouter = (props) => {

    const [init, setInit] = useState(0)
    useEffect(() => {
        let token = Util.getUrlParam('token')
        // console.log(token)
        // let token='21b2d26b-d924-45c5-ab4e-afddae879936'
        if (token) {
            sessionStorage.setItem('token', token)
        }
        setInit(1)
    });
    return (
        <>
            {
                init == 1 ?
                    <>
                        <Route path={props.match.url + "/poster"} component={Poster}/>
                        <Route path={props.match.url + "/rank"} component={Rank}/>
                        <Route path={props.match.url + "/newrank"} component={newRank}/>
                        <Route path={props.match.url + "/scancodegroup"} component={ScanCodeGroup}/>
                    </>
                    : ''
            }

        </>
    )
};

export default FissionCustomerRouter