import Api from "./Api";
import {Toast} from "antd-mobile";
import {Path} from "./path";

class Util {

    /**
     * 改变浏览器title
     * @param title
     */
    static changeHtmlTitle(title) {
        document.title = title;
    }

    static parseParmStr(parmStr) {
        let parms = {};
        if (parmStr.indexOf("?") != -1) {
            let index = parmStr.indexOf('?');
            parmStr = parmStr.substr(index + 1);
            let strs = parmStr.split('&'); //["userName=mona", "pwd=3"]

            for (var i = 0; i < strs.length; i++) {
                parms[strs[i].split('=')[0]] = strs[i].split('=')[1];
            }
        }
        return parms;
    }

    static getUrlParam(key) {
        let params = Util.parseParmStr(window.location.href);
        return params[key];
    }

    static formType() {
        return {
            input: {
                type: "cmpt_input",
                text: "单行文本",
                key: "input"
            },
            textarea: {
                type: "cmpt_textarea",
                text: "多行文本",
                key: "textarea"
            },
            num: {
                type: "cmpt_num",
                text: "数字",
                key: "num"
            },
            radio: {
                type: "cmpt_radio",
                text: "单选",
                key: "radio"
            },
            checkbox: {
                type: "cmpt_checkbox",
                text: "多选",
                key: "checkbox"
            },
            select: {
                type: "cmpt_select",
                text: "下拉框",
                key: "select"
            },
            date: {
                type: "cmpt_date",
                text: "日期时间",
                key: "date"
            },
            image: {
                type: "cmpt_image",
                text: "图片展示",
                key: "image"
            },
            address: {
                type: "cmpt_address",
                text: "地址",
                key: "address"
            },
            score: {
                type: "cmpt_score",
                text: "评分",
                key: "score"
            },
            edit: {
                type: "cmpt_edit",
                text: "文本描述",
                key: "edit"
            },
            name: {
                type: "cmpt_name",
                text: "姓名",
                key: "name"
            },
            phoneno: {
                type: "cmpt_phoneno",
                text: "手机号",
                key: "phoneno"
            },
            email: {
                type: "cmpt_email",
                text: "邮箱",
                key: "email"
            },
            gongsi: {
                type: "cmpt_gongsi",
                text: "公司",
                key: "gongsi"
            },
            bumen: {
                type: "cmpt_bumen",
                text: "部门",
                key: "bumen"
            },
            zhiwei: {
                type: "cmpt_zhiwei",
                text: "职位",
                key: "zhiwei"
            },
            chengwei: {
                type: "cmpt_chengwei",
                text: "称谓",
                key: "chengwei"
            },
            sex: {
                type: "cmpt_sex",
                text: "性别",
                key: "sex"
            },
            birthday: {
                type: "cmpt_birthday",
                text: "生日",
                key: "birthday"
            },
            remark: {
                type: "cmpt_remark",
                text: "备注",
                key: "remark"
            },
            youbian: {
                type: "cmpt_youbian",
                text: "邮编",
                key: "youbian"
            },
            idcard: {
                type: "cmpt_idcard",
                text: "身份证号",
                key: "idcard"
            },
            weixin: {
                type: "cmpt_weixin",
                text: "微信",
                key: "weixin"
            },
            qq: {
                type: "cmpt_qq",
                text: "QQ",
                key: "qq"
            },
            weibo: {
                type: "cmpt_weibo",
                text: "微博",
                key: "weibo"
            },
            citypicker: {
                type: "cmpt_citypicker",
                text: "地址",
                key: "phoneno"
            },
            textareaOld: {
                type: "textarea",
                text: "标题"
            },
            btn: {
                type: "cmpt_btnword",
                text: "提交"
            },
        }
    }

    static overwriteImg(html) {
        let clientW = document.documentElement.clientWidth || document.body.clientWidth
        if (clientW > 750) {
            clientW = 750
        }
        clientW = clientW - 30;
        let wrapper = document.createElement('div');
        wrapper.innerHTML = html;
        let imgs = wrapper.getElementsByTagName('img');

        for (let i = 0; i < imgs.length; i++) {
            let item = imgs[i];
            let width = item.getAttribute('width');
            let height = item.getAttribute('height');
            // let rateNew = width / 280
            if (width > 0) {
                let rate = clientW / 280;
                if (rate > 1) {
                    height = Number(height * rate);
                    width = Number(width * rate);
                    height = height.toFixed(0);
                    width = width.toFixed(0);
                    item.setAttribute('height', height);
                    item.setAttribute('width', width);
                    item.setAttribute('mode', 'aspectFit')
                    // mode='aspectFit'
                }
            }

        }

        return wrapper.innerHTML;
    }
    static isEmpty(val) {
        // val = val.trim();
        if (val == null || String(val) == '' || typeof val == 'undefined') {
            return true;
        }
        return false;
    }
    static getAntdDistrictZh(code, antdDistrict) {
        let addZh = [];
        let pCode = code[0];
        let cCode = code[1];
        let qCode = undefined;
        if (code.length > 2) {
            qCode = code[2];
            for (let i = 0; i < antdDistrict.length; i++) {
                let pData = antdDistrict[i];
                if (pData.value == pCode) {
                    addZh.push(pData.label);
                }
                let cArr = pData.children;
                for (let m = 0; m < cArr.length; m++) {
                    let cData = cArr[m];
                    if (cData.value == cCode) {
                        addZh.push(cData.label);
                    }
                    let qArr = cData.children;
                    for (let k = 0; k < qArr.length; k++) {
                        let qData = qArr[k];
                        if (qData.value == qCode) {
                            addZh.push(qData.label);
                            return addZh[0] + ' ' + addZh[1] + ' ' + addZh[2];
                        }
                    }
                }
            }
        } else {
            for (let i = 0; i < antdDistrict.length; i++) {
                let pData = antdDistrict[i];
                if (pData.value == pCode) {
                    addZh.push(pData.label);
                }
                let cArr = pData.children;
                for (let m = 0; m < cArr.length; m++) {
                    let cData = cArr[m];
                    if (cData.value == cCode) {
                        addZh.push(cData.label);
                        return addZh[0] + ' ' + addZh[1];
                    }
                }
            }
        }
    }

    static checkNum(email) {
        var emailReg = /^[0-9]*$/;
        if (!emailReg.test(email)) {
            return false;
        }
        return true;
    }

    static checkPhone(data) {
        let reg = /^1[23456789]\d{9}$/
        if (!reg.test(data)) {
            return false
        } else {
            return true
        }
    }

    static checkEmail(email) {
        var emailReg = /^(\w)+(\.\w+)*@(\w)+((\.\w{2,6}){1,3})$/;
        if (!emailReg.test(email)) {
            return false;
        }
        return true;
    }

    // 分，换算成元
    static centTransformOut(num) {
        num = parseFloat(num) / 100;
        let price = 0;
        if (isNaN(num) || num < 0) {
            price = 0.00;
        } else {
            price = num;
        }

        price = price.toFixed(2);

        return price;
    }

    //去除富文本带过来的 标签 和 空格
    static removeTAG(str, len) {
        str = str.replace(/&nbsp;/g, " ");
        str = str.replace(/<[^>]+>/g, "");
        return str;
    }

    //技术支持   0 未绑定 1 已经绑定  -1 请求超时
    static getVipInfo() {
        const p = new Promise((resolve, reject) => {
            Api.checkWeChatBind(data => {
                resolve(data.isbound)
            }, error => {
                resolve(-1)
            })
        })
        return p
    }

    //技术支持跳页面
    static toPath(type) {
        let path
        if (type == 0) {
            path = Path.getReceiveVip()
        }
        else if (type == 1) {
            path = Path.getPcenter()
        }
        else {
            path = undefined
        }
        return path
    }

    //好友助力失败 原因
    static getReason(type) {
        //未助力原因 1:不是新客户,2:删除员工好友后助力失效，3在黑名单，4已有有效助力
        if (type == 1) return '不是新客户'
        if (type == 2) return '删除好友后助力失效'
        if (type == 3) return '在黑名单'
        if (type == 4) return '已有有效助力'
        return ''
    }
    static isPhoneNo(phoneNo) {
        let phoneNoReg = /^1[3456789]\d{9}$/;
        return phoneNoReg.test(phoneNo);
    }
    static isCard(str) {
        let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        return reg.test(str);
    }
    static isEmail(email) {
        let emailReg = /^(\w)+(\.\w+)*@(\w)+((\.\w{2,6}){1,3})$/;
        return emailReg.test(email);
    }
}

export default Util































