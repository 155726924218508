import {Icon} from "antd-mobile";

/**
 * @author Yaochao
 * @date 2021/8/6 17:15
 * @description
 */
import React from 'react'

const Loading = () => {

    return (
        <div className='loading'>
            <Icon type='loading' size={'lg'}/>
        </div>
    )
};

export default Loading