import React, {Component} from 'react';
import {Route} from "react-router-dom";
import loadable from "../loadable";

const FormMiddle = loadable(() => import('./middlePage/formMiddle.js'));
const FormSuccess = loadable(() => import('./middlePage/success.js'));

class Form extends Component {
    render() {
        return (
            <div>
                <Route exact path={this.props.match.url + "/detail"} component={FormMiddle}/>
                <Route path={this.props.match.url + "/success"} component={FormSuccess}/>
            </div>
        );
    }
}

export default Form;