import React, {Component} from 'react';
import QrCodeMode from "./qrCodeMode";
import SelectGroup from "./selectgroup";
import PosterMode from "./posterMode";
import Api from "../../util/Api";
import {Toast, ActivityIndicator} from "antd-mobile";
import ActivityEnd from "./activityEnd";

const query = 'eyJjb2RlSWQiOjI1LCJvcGVuSWQiOiJvNkhOSjV0YzB0WjUtRnpzQ2I3Z2tGQmRsaHFNIiwibG9nSWQiOjQ5fQ'

class Index extends Component {

    state = {
        type: -1, //0：活动结束，1：分组页面，2：二维码标准模式，3：二维码海报模式
    };

    componentDidMount() {
        this.getPageType();
    }

    getData = () => {
        let pathname = this.props.location.pathname;
        let last = pathname.lastIndexOf('/');
        let data = pathname.substring(last + 1);
        return data;
    };

    getPageType = () => {
        Api.getRliveCodePageType(this.getData(), data => {
            this.setState({
                type: data.type
            })
        }, error => {
            Toast.fail('请求失败')
        })
    };

    setType = (type) => {
        this.setState({
            type: type
        })
    };

    render() {
        const {type} = this.state;
        return (
            <div className={'live-code-main'}>
                {
                    //0：活动结束，1：分组页面，2：二维码标准模式，3：二维码海报模式
                    type === 1 ?
                        <SelectGroup queryData={this.getData()} setType={this.setType}/> :
                        type === 2 ?
                            <QrCodeMode queryData={this.getData()}/> :
                            type === 3 ?
                                <PosterMode queryData={this.getData()}/> :
                                type === 0 ?
                                    <ActivityEnd/> :
                                    type === -1 ?
                                        <div className={'livecode-loading'}>
                                            <ActivityIndicator animating text={'加载中...'}/>
                                        </div> : ''

                }
            </div>
        );
    }
}

export default Index;